import React from 'react'
import styled from 'styled-components'
import Feature1 from '../Assets/features/key-features01.png'
import Feature2 from '../Assets/features/key-features02-edited.png'
import Feature3 from '../Assets/features/key-features03.png'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 130px 80px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    h1 {
        font-size: 44px;
    }
`;

const FeaturesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 100px;
    @media (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
    }
`;

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        color: black;
        transform: translateY(-220%);
        transition:color 0.3s ease, transform 0.3s ease, z-index 0.3s ease;
        z-index: 0;
    }

    img {
        min-width: 220px;
        width: 428px;
        height: auto;
        transition: transform 0.3s ease;
        z-index:1;
    }

    img:hover {
        transform: scale(1.1);
    }

    img:hover + p {
        color: white;  /* Change the color of the p tag when the img is hovered */
        transform: translateY(-100%);
        z-index:1;
    }

    @media (max-width: 800px) {
        width: 350px;
    }
`;

const Features = () => {
    const redirect = (link) => {
        window.open(link, '_blank');  // This opens the link in a new tab
    };
    return (
        <Container>
            <TitleContainer>
                <h1>
                    KEY FEATURES <span className="hide-small">OF HERMY COIN</span>
                </h1>
            </TitleContainer>
            <FeaturesWrapper>
                <FeatureContainer>
                    <img
                        src={Feature1}
                        onClick={() => redirect("https://t.me/Scratch2Winbot")}
                   />
                   <p>CLICK!</p>
                </FeatureContainer>
                <FeatureContainer>
                    <img
                        src={Feature2}
                        onClick={() => redirect("https://t.me/Scratch2Winbot")}
                    />
                    <p>CLICK!</p>
                </FeatureContainer>
                <FeatureContainer>
                    <img
                        src={Feature3}
                        onClick={() => redirect("https://t.me/Scratch2Winbot")}
                    />
                    <p>CLICK!</p>
                </FeatureContainer>
            </FeaturesWrapper>
        </Container>
    );
};

export default Features;
