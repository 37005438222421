import React, { useEffect, useState } from 'react';
import './Roadmap.css'; // Assuming you are using a CSS file for styling
import Step1 from '../Assets/roadmap/1step.png'
import Step2 from '../Assets/roadmap/2step.png'
import Step3 from '../Assets/roadmap/3step.png'
import Step4 from '../Assets/roadmap/4step.png'
import ArrowCustom from '../Assets/Arrows/whitearrow.svg'
import GraySolid from '../Assets/graybg.jpg'
import styled from 'styled-components';
const Roadmap = () => {
    const [isVisible, setIsVisible] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Set initial state based on window width
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        isVisible ? (
            <div className="roadmap-container">
                <div className="header-designer-ultra-smart-bb-gg roadmap-title">ROADMAP</div>
                <div className="roadmap-grid">
                    <div className='roadmap-item arrow'>
                        <img src={ArrowCustom} id='right' />
                    </div>
                    <div className="roadmap-item ">
                        <img src={Step1} alt="Get $Hermy" />
                        <p>IN A WORLD OF UNFAIR LAUNCHES - $HERMY IS THE EXCEPTION</p>
                    </div>
                    <div className='roadmap-item arrow'>
                        <img src={ArrowCustom} id='down' />
                    </div>
                    <div className="roadmap-item">
                        <img src={Step4} alt="Degen's Wildest party" />
                        <p>LISTING ON TIER 1 EXCHANGES AND FULL MARKETPLACE ROLLOUT</p>
                    </div>
                    <div className='roadmap-item' id='empty' >
                    </div>
                    <div className="roadmap-item">
                        <img src={Step2} alt="Hangover pill" />
                        <p>LAUNCH HERMYPOINTS SYSTEM AND REFERRAL QUESTS</p>
                    </div>
                    <div className='roadmap-item arrow'>
                        <img src={ArrowCustom} id='up' />
                    </div>
                    <div className="roadmap-item">
                        <img src={Step3} alt="Grind mode" />
                        <p>LAUNCH LUXURY MARKETPLACE BETA</p>
                    </div>

                    <div className='roadmap-item arrow' id='left'>
                        <img src={ArrowCustom}></img>
                    </div>
                </div>
            </div>
        ) : (
            <div className="roadmap-container">
                <div className="header-designer-ultra-smart-bb-gg" style={{ marginBottom: "80px" }}>ROADMAP</div>

                <div className="roadmap-grid">


                    <div className="roadmap-item ">
                        <img src={Step1} alt="Get $Hermy" />
                        <p>IN A WORLD OF UNFAIR LAUNCHES, $HERMY IS THE EXCEPTION</p>
                    </div>
                    <div className='roadmap-item arrow' style={{ margin: "20px", width: "10vw", transform: "translateX(350%) translateY(100%) rotate(160deg) scaleX(1) scale(1.5)" }}>
                        <img src={ArrowCustom} id='right' />
                    </div>
                    <div className="roadmap-item">
                        <img src={Step2} alt="Degen's Wildest party" />
                        <p>LAUNCH HERMYPOINTS SYSTEM AND REFERRAL QUESTS</p>
                    </div>

                    <div className='roadmap-item arrow' style={{ margin: "20px", width: "10vw", transform: "translateX(-350%) translateY(30%) rotate(200deg) scaleX(-1) scale(1.5)" }}>
                        <img src={ArrowCustom} id='right' />
                    </div>

                    <div className="roadmap-item">
                        <img src={Step3} alt="Hangover pill" />
                        <p>LAUNCH LUXURY MARKETPLACE BETA</p>
                    </div>
                    <div className='roadmap-item arrow' style={{ margin: "20px", width: "10vw", transform: "translateX(350%) translateY(50%) rotate(160deg) scaleX(1) scale(1.5)" }}>
                        <img src={ArrowCustom} id='right' />
                    </div>
                    <div className="roadmap-item">
                        <img src={Step4} alt="Grind mode" />
                        <p>LISTING ON TIER 1 EXCHANGES AND FULL MARKETPLACE ROLLOUT</p>
                    </div>

                </div>
            </div>
        )
    );
};
export default Roadmap;