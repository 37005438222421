import React from 'react';
import './Available.css';

const Available = () => {
    const svgItems = [
        require('../Assets/american-visa-mastercard.png'),
        require('../Assets/apple-pay.png'),
        require('../Assets/dex.png'),
        require('../Assets/dexscreener.png'),
        require('../Assets/jupiter.png'),
        require('../Assets/coingecko_tba.png'),
        require('../Assets/coinmarket_tba.png'),
        require('../Assets/mexc_tba.png'),
        require('../Assets/okx_tba.png'),
        require('../Assets/paypal_tba.png'),
    ];

    return (
        <div className="available-section-container">
            <div className="header-designer-ultra-smart-bb-gg">AVAILABLE ON</div>
            <div className="svg-grid">
                {svgItems.map((item, index) => (
                    <div className="svg-item" key={index}>
                        <img src={item} alt={`Logo ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Available;
