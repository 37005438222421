import React from "react";
import Logo from "../Assets/logo-1.png";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import XIcon from "@mui/icons-material/X";
// import TikTokIcon from "./TikTokIcon";
import InstagramIcon from "../Assets/icons/instagram.png"
import TelegramIcon from "../Assets/icons/telegram.png"
import TikTokIcon from "../Assets/icons/tiktok.png"
import XIcon from "../Assets/icons/x.png"
import { X } from "@mui/icons-material";
const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <div className="logo">
            $HERMY
          </div>
        </div>
        <div className="support">support@hermy.com<br />2024  $Hermy. All right reserved.</div>
      </div>
      <div className="footer-section-two">
        <div className="footer-text">
          <div>
            DISCLAIMER: $HERMY is a meme coin created for fun with absolutely no intrinsic value or any expectation of financial return. The token for entertainment purposes only and we take zero responsibility for the value of this token. $HERMY is inspired to be the token for luxury goods swap on the SOL blockchain.</div>
          <div className="social-media-buttons" style={{ "margin-top": "100px" }}>
            <a href="https://www.instagram.com/hermydegen" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={InstagramIcon}></img>
            </a>
            <a href="https://t.me/hermyfam" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={TelegramIcon}></img>
            </a>
            <a href="https://x.com/HermyCoin" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={XIcon}></img>
            </a>
            <a href="https://tiktok.com/@hermycoin" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={TikTokIcon}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
