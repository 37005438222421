import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import videoWebM from '../Assets/stop.webm';  // Path to WebM file

const VideoBackground = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const tryPlayVideo = () => {
            if (videoElement) {
                videoElement.play().catch((error) => {
                    // Fallback if autoplay is blocked (e.g., on iOS or other devices)
                    console.log('Autoplay failed, trying to play after user interaction');
                });
            }
        };

        // Try to autoplay after a small delay, or after user interaction
        tryPlayVideo();

        // Listen for user interaction to trigger play
        const handleUserInteraction = () => {
            tryPlayVideo();
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };

        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, []);

    return (
        <Container>
            <Background>
                <Video
                    ref={videoRef}
                    src={videoWebM}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/webm"
                />
                {/* Overlay added here */}
                <Overlay />
            </Background>
            <TextWrapper>
                <Title>TIME TO PUMP</Title>
                <Subtitle>$HERMY</Subtitle>
            </TextWrapper>
        </Container>
    );
};

export default VideoBackground;

// Styled Components
const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden; /* Ensures no scrollbars appear */
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the whole screen */
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1;
`;

const TextWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
`;

const Title = styled.h1`
    font-size: 3rem;
    text-shadow: 0 0 10px #E85D04;
`;

const Subtitle = styled.h2`
    font-size: 4rem;
    text-shadow: 0 0 10px #E85D04;

`;
