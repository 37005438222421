import React from "react";
import AboutBackgroundImage from "../Assets/about.PNG";
import './About.css';

const About = () => {
  return (
    <div className="about-section-container" id="about">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <div className="header-designer-ultra-smart-bb-gg" style={{ display: "flex", flexDirection: "row" }}>
          ABOUT HERMY COIN
        </div>
        <p style={{ fontStyle: "normal" }}>
          Hermy is the first memecoin to combine luxury and crypto! Invest, have fun, and get rewarded — with chances to win luxury items or buy them at Hermy Marketplace
        </p>
        <div className="about-buttons-container">
          <div className="buy-button-container">
            <br/>
            <a 
              href="https://raydium.io/swap/?inputMint=sol&outputMint=FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump" 
              className="buy-button2" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              BUY $HERMY
            </a>
            <a 
              href="#" 
              className="marketplace-button" 
            >
              MARKETPLACE
              <div style={{ fontFamily: "ABeeZee", fontSize: "0.95rem", fontStyle: "italic" }}>
                coming soon
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
