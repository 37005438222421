/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './HowToBuy.css';
import Step1 from '../Assets/cards/1.png'
import Step2 from '../Assets/cards/2.png'
import Step3 from '../Assets/cards/3.png'
import Step4 from '../Assets/cards/4.png'
import glow from '../Assets/glow.png'
const HowToBuy = () => {

    return (
        <>
            <div className="glow-background"></div>
            <div className="how-to-buy-container" id='howtobuy'>
                <div className="header-designer-ultra-smart-bb-gg" style={{ marginBottom: "30px" }}>HOW TO BUY</div>
                <div className="steps-container">
                    <div className='step-card step-0'>
                        <img src={Step1} />
                    </div>
                    <div className='step-card step-1'>
                        <img src={Step2} />
                    </div>
                    <div className='step-card step-2'>
                        <img src={Step3} />
                    </div>
                    <div className='step-card step-3'>
                        <img src={Step4} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HowToBuy;
