import React, { useState } from 'react';
import chartPicture from '../Assets/chart.png';
import styled, { keyframes } from 'styled-components';
/*
const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(238, 90, 2, 0.7);
  }
  100% {
    box-shadow: 0 0 30px rgba(238, 90, 2, 1);
  }
`;
*/
const flyoutAnimation = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const CopyMessage = styled.div`
  color: white;
  font-size: 1rem;
  margin-top: 10px;
  position: relative;
  animation: ${({ show }) => (show ? flyoutAnimation : 'none')} 2s forwards;
`;

const MadnomicsContainer = styled.div`
  background-color: transparent;
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;

  .madnomics-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .third-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 70vh;

      .contract {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        @media (max-width: 800px) {
          align-items: center;
          span {
            font-size: 1.1rem;
          }
        }

        span {
          color: white;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .madnomics-container {
      flex-direction: column;
      justify-content: space-between;
    }

    .third-section {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      height: 30vh !important;
      margin-left: 4vw;
    }

    .contract {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-height: 30vh;
      font-size: 2vw;
    }
  }

  @media (max-width: 600px) {
    .text-container {
      font-size: 24px;
    }
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: white;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: clamp(200px, 90vw, 400px);
  height: 400px;
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    color: white;
    font-weight: bold;
    font-size: 32px;
  }
`;

const SupplyText = styled.div`
  text-align: left;
  color: white;
  font-size: 32px;
  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

const ContractContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  background-color: white;
  border-radius: 100px;
  padding: 2px;
  width: 539px;
  height: 49px;

  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const ContractInput = styled.div`
  font-size: 1.25rem;
  font-family: 'Humanist', sans-serif;
  flex: 1;
  padding: 0  0 0 15px;
  cursor: pointer;
  background-color: white;
  border-radius: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const BuyButton = styled.button`
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  padding: 10px 20px;
  background-color: #ee5a02;
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7a1b;
  }
`;

const Madnomics = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = 'FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump';

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <MadnomicsContainer id="hermynomics">
      <div className="header-designer-ultra-smart-bb-gg" style={{ marginTop: '130px', marginBottom: '50px' }}>
        HERMYNOMICS
      </div>
      <div className="madnomics-container" style={{ marginBottom: '130px' }}>
        <div className="first-section">
          <TextContainer className="text-container">
            <div style={{ fontSize: '32px' }}>
              LIQUIDITY BURNED
              <br />
              OWNERSHIP REVOKED
            </div>
          </TextContainer>
        </div>
        <div className="second-section">
          <ChartContainer>
            <img src={chartPicture} alt="Chart" />
          </ChartContainer>
        </div>
        <div className="third-section">
          <SupplyText className="supply">
            SUPPLY: <br /> 1,000,000,000,000
          </SupplyText>

          <div className="contract">
            <span>CONTRACT ADDRESS (CLICK TO COPY)</span>
            <ContractContainer>
              <ContractInput onClick={handleCopy}>{contractAddress}</ContractInput>
              <BuyButton
                onClick={() =>
                  window.open(
                    'https://raydium.io/swap/?inputMint=sol&outputMint=FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump',
                    '_blank'
                  )
                }
              >
                Buy
              </BuyButton>
            </ContractContainer>
            {copied && <CopyMessage show={copied}>ADDRESS COPIED TO CLIPBOARD!</CopyMessage>}
          </div>
        </div>
      </div>
    </MadnomicsContainer>
  );
};

export { ContractContainer, ContractInput, BuyButton };
export default Madnomics;
