import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TriangleFilled from '../Assets/Arrows/triangle.svg';

const FAQSection = styled.section`
  margin-top: 130px;
  background-color: transparent; 
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  /* Make it more responsive */
  @media (max-width: 1024px) {
    margin: 5vw 15vw;
  }
  @media (max-width: 768px) {
    margin: 5vw 10vw;
    padding: 30px 0;
  }
  @media (max-width: 480px) {
    margin: 5vw 5vw;
    padding: 20px 0;
  }
`;

const FAQHeading = styled.h1`
  font-size: 4vw; /* Use vw for responsiveness */
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;

  /* Adjust for smaller screens */
  @media (max-width: 1024px) {
    font-size: 5vw;
  }
  @media (max-width: 768px) {
    font-size: 6vw;
  }
`;

const FAQItem = styled.div`
  background-color: transparent;
  border: 5px solid #F6C060;
  box-shadow: 0px 0px 20px 0px #FF6F1E;
  border-radius: 18px;
  margin: 10px 0;
  cursor: pointer;
  overflow: hidden;
  min-height: 86px;
  width: 100%;
  max-width: 1010px;

  @media (max-width: 768px) {
    margin: 8px 0;
  }
  @media (max-width: 480px) {
    margin: 6px 0;
  }
`;

const FAQHeader = styled.div`
  padding: 15px;
  font-size: 32px; /* Adaptive font size */
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: space-between;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 5vw;
  }
  @media (max-width: 480px) {
    font-size: 4vw;
  }
`;

const PlusIcon = styled.span`
  font-size: 1.5rem;
  transform-origin: center 14px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const MotionContent = styled(motion.div)`
  background-color: transparent;
  padding: 0 15px;
  padding-bottom: 10px;
  color: white;
  overflow: hidden;
  text-align: left;
  
  p {
    font-size: 26px; /* Adaptive font size */
    font-style: italic;
    font-family: "ABeeZee", sans-serif !important;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    p {
      font-size: 4vw;
    }
  }
  @media (max-width: 480px) {
    padding: 0 8px;
    p {
      font-size: 5vw;
    }
  }
`;

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: "WHAT BLOCKCHAIN IS HERMY ON?", answer: "Hermy is on SOLANA" },
    { 
      question: "WHAT'S THE TOKEN CONTRACT ADDRESS?", 
      answer: "The token contract address for Hermy is FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump. You can use this address to trade or verify the token on the Solana blockchain. It's available on popular platforms such as Jupiter (https://jup.ag/), DexTools (https://www.dextools.io/), and Raydium.io (https://raydium.io/)." 
    },
    { question: "WHAT'S THE VISION OF HERMY?", answer: "Blending memecoin excitement with luxury appeal. We’re here to give our community thrilling crypto experiences and exclusive access to high-end products. Join us in redefining luxury in the crypto world!" },
    { question: "WHAT'S THE HERMY VIP LIST?", answer: "An exclusive club for top players—score a spot to join weekly luxury giveaways and unlock premium perks!" },
    { question: "WHAT'S THE HERMY PROMOTERS CLUB?", answer: "Our top referrers’ exclusive zone! Earn extra airdrops and unique rewards just for spreading the word about $Hermy." }
  ];

  return (
    <FAQSection id='faq'>
      <div className="header-designer-ultra-smart-bb-gg">FAQ</div>
      {faqData.map((faq, index) => (
        <FAQItem key={index} onClick={() => toggleFAQ(index)}>
          <FAQHeader>
            {faq.question}
            <PlusIcon isOpen={activeIndex === index}><img src={TriangleFilled}></img></PlusIcon>
          </FAQHeader>
          <MotionContent
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: activeIndex === index ? 'auto' : 0,
              opacity: activeIndex === index ? 1 : 0,
            }}
            transition={{ duration: 0.2 }}
          >
            <p>{faq.answer}</p>
          </MotionContent>
        </FAQItem>
      ))}
    </FAQSection>
  );
};

export default FAQ;
